import Skeleton from './Skeleton'

export const TravelSearchOfferSkeleton = () => {
  return (
    <div className={`ts-flex ts-bg-transparent ts-w-fit`}>
      <div className="ts-font-medium md:ts-right-0 mobile:ts-left-0 ts-p-dfds-s lg:ts-hidden">
        <Skeleton className="ts-h-[38px] md:ts-h-[42px] ts-w-[142px]" />
      </div>
    </div>
  )
}
