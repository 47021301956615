import { ChevronRightIcon, YesIcon } from '@dfds-frontend/compass-ui'
import { useTranslation } from 'react-i18next'
import { VehicleIcon } from './VehicleIcon'
import { isMobile, isTablet } from 'react-device-detect'

interface Props {
  isVehicleMandatory: boolean
  categories: string[]
  selectedCategoryCode: string | undefined
  walkonIsSelected: boolean
  onSelectCategory: (categoryCode: string) => void
}

const Categories: React.FC<Props> = ({ isVehicleMandatory, categories, selectedCategoryCode, walkonIsSelected, onSelectCategory }) => {
  const { t } = useTranslation()

  return (
    <>
      {!selectedCategoryCode && (
        <ul data-e2e="vehicle-categories" data-travel-search-child className="ts-border-0 ts-border-solid ts-list-none ts-p-0 ts-m-0">
          {categories.map((category, index) => (
            <li
              data-e2e={`vehicle-category-${category}`}
              key={category}
              className={`ts-list-none ts-px-dfds-s ts-m-0 ts-flex ts-gap-dfds-s ts-min-h-[90px] ts-border-solid ts-border-0 ts-cursor-pointer ts-items-center  ts-bg-dfds-surface-light ts-pr-dfds-s ${!isMobile && !isTablet && 'hover:ts-bg-cvi-brand-brand100'} ${
                index === categories.length - 0 && isVehicleMandatory ? 'ts-border-b-0' : 'ts-border-b ts-border-b-dfds-divider-dark'
              }`}
              onClick={() => {
                onSelectCategory(category)
              }}
            >
              <div className="ts-text-cvi-brand-brand900 ts-flex ts-h-full ts-items-center ts-max-w-[46px] ts-min-w-[46px]">
                <VehicleIcon code={category} size="lg" />
              </div>
              <label className="ts-text-cvi-brand-brand900 ts-text-base ts-font-medium ts-cursor-pointer">
                {t(`VEHICLE-CATEGORY-${category}`)}
              </label>
              <div className="ts-text-cvi-brand-brand900 ts-ml-auto">
                <ChevronRightIcon size="md" />
              </div>
            </li>
          ))}
          {!isVehicleMandatory && (
            <li
              data-e2e="vehicle-NCAR"
              key="walkon"
              className={`ts-list-none ts-px-dfds-s ts-m-0 ts-flex ts-gap-dfds-s ts-h-[90px] ts-border-solid ts-border-b ts-border-b-dfds-divider-dark ts-cursor-pointer ts-items-center ts-pr-dfds-s ${!isMobile && !isTablet && 'hover:ts-bg-cvi-brand-brand100'} ${
                walkonIsSelected
                  ? 'ts-bg-cvi-states-selected'
                  : `ts-bg-dfds-surface-light ${!isMobile && !isTablet && 'hover:ts-bg-cvi-brand-brand100'}`
              }`}
              onClick={() => {
                onSelectCategory('NCAR')
              }}
            >
              <div className="ts-text-cvi-brand-brand900 ts-flex ts-h-full ts-items-center ts-max-w-[46px] ts-min-w-[46px]">
                <VehicleIcon code="NCAR" size="lg" />
              </div>
              <label className="ts-text-cvi-brand-brand900 ts-text-base ts-font-medium ts-cursor-pointer">
                {t('VEHICLE-CATEGORY-FOOTPASSENGER')}
              </label>
              {walkonIsSelected && (
                <div className="ts-ml-auto ts-text-cvi-brand-brand900">
                  <YesIcon size="md" />
                </div>
              )}
            </li>
          )}
        </ul>
      )}
    </>
  )
}

export default Categories
