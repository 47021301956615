import * as Switch from '@radix-ui/react-switch'
import clsx from 'clsx'

type ToggleProps = {
  name: string
  defaultChecked?: boolean
  disabled?: boolean
  onCheckedChange: (checked: boolean) => void
}

export const Toggle: React.FC<ToggleProps> = ({ name, defaultChecked, disabled, onCheckedChange }) => {
  return (
    <div className="ts-h-[40px] ts-flex ts-items-center" data-disabled={disabled} data-e2e={`${name}-switch`}>
      <Switch.Root
        checked={defaultChecked}
        disabled={disabled}
        defaultChecked={defaultChecked}
        onCheckedChange={onCheckedChange}
        className={clsx(
          'ts-relative ts-h-[24px] ts-w-[40px] ts-rounded-full ts-outline-none',
          disabled
            ? 'ts-cursor-not-allowed ts-bg-cvi-grey-grey100'
            : defaultChecked
              ? 'data-[state=checked]:ts-bg-cvi-brand-brand500'
              : 'ts-bg-cvi-grey-grey300  ts-cursor-pointer'
        )}
      >
        <Switch.Thumb
          className={clsx(
            'ts-block ts-size-[18px] ts-translate-x-[4px] ts-rounded-full ts-bg-white ts-transition-transform ts-duration-200 ts-will-change-transform data-[state=checked]:ts-translate-x-[18px]',
            disabled ? 'ts-bg-cvi-grey-grey50' : 'ts-bg-white'
          )}
        />
      </Switch.Root>
    </div>
  )
}
