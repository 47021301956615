import { useEffect, useRef } from 'react'
import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from '@dfds-frontend/compass-ui'
import clsx from 'clsx'
import { useElementWidth } from '../../Hooks/useElementWidth'

interface Props {
  title: string
  isMobile: boolean
  fieldName: 'route' | 'vehicle' | 'passenger' | 'currency'
  isOpen: boolean
  children: React.ReactNode
  value: any
  isLoading: boolean
  icon: React.ReactNode
  disabled?: boolean
  visibleRows?: 1 | 2
  dataAttrList: { name: string; value: string }[]
  onClick: (clickedOutSide: boolean) => void
  onClose: () => void
}

const SelectField: React.FC<Props> = ({
  title,
  fieldName,
  children,
  isMobile,
  isOpen,
  icon,
  value,
  isLoading,
  dataAttrList,
  visibleRows,
  disabled,
  onClick,
  onClose,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const selectFieldWidth = useElementWidth(ref)
  let dataAttrProps = {} as ComponentProps
  dataAttrList.map((dataAttr) => {
    const key = `data-e2e-${dataAttr?.name}`
    const dataAttrKey = key as keyof ComponentProps
    dataAttrProps[dataAttrKey] = dataAttr.value?.toString() ?? ''
  })
  const keyLoading = `data-e2e-${fieldName}-loading`
  const dataAttrKeyLoading = keyLoading as keyof ComponentProps
  dataAttrProps[dataAttrKeyLoading] = isLoading.toString()

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        const target = event.target as HTMLElement
        if (!target.closest('[data-travel-search-child]')) onClick(true)
      }
    }

    if (isOpen && !isMobile && ref) addEventListener('click', handleClickOutside)
    if (!isOpen && ref) window.removeEventListener('click', handleClickOutside)
    return () => window.removeEventListener('click', handleClickOutside)
  }, [isOpen, ref])

  return (
    <div
      ref={ref}
      data-e2e={fieldName}
      data-e2e-open={isOpen}
      data-e2e-disbaled={`${fieldName}-${disabled}`}
      className="ts-relative ts-h-full h-[58px]"
      {...dataAttrProps}
    >
      <div
        className="ts-h-full"
        onClick={() => {
          if (!disabled) {
            if (!isOpen) document.getElementById(`${fieldName}-input`)?.focus()
            else onClose()
          }
        }}
      >
        <div
          id={`${fieldName}-field`}
          className={clsx(
            'ts-z-1 ts-absolute ts-bottom-0 ts-left-0 ts-right-0 ts-top-0 ts-to-transparent',
            disabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'
          )}
        ></div>
        <div className="ts-bg-white ts-w-full ts-h-full ts-flex ts-flex-row ts-gap-dfds-xs ts-items-center ts-px-dfds-xs">
          <div className={clsx(disabled ? 'ts-text-cvi-grey-grey300' : 'ts-text-cvi-brand-brand900')}>{icon}</div>
          <div className="ts-truncate ts-w-full ts-flex ts-flex-col ts-justify-center">
            <label
              className={clsx(
                'ts-select-none ts-text-xs',
                disabled ? 'ts-cursor-not-allowed ts-text-cvi-grey-grey300' : 'ts-cursor-pointer ts-text-cvi-grey-grey700'
              )}
            >
              {title}
            </label>
            <input
              data-e2e={`${fieldName}-input`}
              readOnly
              value={`${isLoading ? '...' : value}`}
              onFocus={() => {
                if (!disabled) {
                  if (!isOpen) {
                    onClick(false)
                  }
                }
              }}
              id={`${fieldName}-input`}
              className={clsx(
                'ts-w-full ts-flex-none ts-border-none ts-font-medium ts-text-cvi-brand-brand900 ts-leading-none ts-select-none focus:ts-outline-none ts-truncate',
                disabled ? 'ts-text-dfds-text-dark-disabled' : 'ts-cursor-pointer'
              )}
            />
          </div>
          <div className={clsx('ts-ml-auto', disabled && 'ts-text-cvi-grey-grey300')}>
            {isOpen ? <ChevronUpIcon size="md" /> : <ChevronDownIcon size="md" />}
          </div>
        </div>
      </div>
      <div
        style={{ minWidth: selectFieldWidth }}
        className={`ts-z-modal ts-bg-white lg:ts-absolute lg:ts-left-0 lg:ts-mt-dfds-xs lg:ts-bg-white lg:ts-shadow-md lg:ts-drop-shadow-xl tabletDown:ts-fixed tabletDown:ts-bottom-0 tabletDown:ts-left-0 tabletDown:ts-right-0 tabletDown:ts-top-0 tabletDown:ts-w-full ${
          !isOpen && 'ts-invisible'
        }`}
      >
        <div className="ts-flex ts-h-full ts-flex-col ts-w-full">
          <div
            id="header-content"
            className="ts-flex ts-h-[64px] ts-min-h-[64px] ts-flex-row ts-items-center ts-bg-dfds-surface-light ts-pl-dfds-s lg:ts-hidden"
          >
            <label className="ts-max-w-[350px] ts-text-xl ts-font-medium ts-text-cvi-brand-brand900">{title}</label>
            <div
              className="ts-fill-bg-500 ts-ml-auto ts-flex ts-h-full ts-w-12 ts-cursor-pointer ts-items-center ts-justify-center hover:ts-text-cvi-brand-brand500"
              onClick={() => {
                if (!disabled) {
                  onClick(true)
                }
              }}
            >
              <CloseIcon size="lg" />
            </div>
          </div>
          <div className="ts-border-b ts-border-solid ts-border-gray-300 lg:ts-hidden"></div>
          <div
            id="main-content"
            className={`ts-bg-dfds-surface-light ts-w-full tabletDown:ts-overflow-y-scroll
              ${fieldName !== 'route' && visibleRows === 1 && 'tabletDown:ts-h-[calc(100%-150px)]'}
              ${fieldName !== 'route' && visibleRows === 2 && 'tabletDown:ts-h-[calc(100%-170px)]'}`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectField

interface ComponentProps {
  dataAttrName: string
  dataAttrValue: string
}
