import clsx from 'clsx'
import React from 'react'

interface SkeletonProps {
  className?: string
}

const Skeleton: React.FC<SkeletonProps> = ({ className = '' }) => {
  return <div className={clsx('ts-animate-pulse ts-bg-slate-700', className)}></div>
}

export default Skeleton
