import { getContentfulLocale, getClientEnvironment, logger } from '@dfds-pax/common'
import { LogProps } from '@dfds-pax/logging-provider'
import { v4 as uuidv4 } from 'uuid'
import { getLocaleFromUrlOrQuery } from './common'

const getContentfulData = async (contentTask: Promise<any>) => {
  const attempts = 3
  let cnt = 1
  while (cnt <= attempts) {
    try {
      await new Promise((resolve) => setTimeout(resolve, 300 * cnt))
      const contentResult = await contentTask
      return contentResult
    } catch (error: any) {
      if (cnt === attempts && ['Failed to fetch', 'Load failed'].includes(error?.message)) {
        const payload: LogProps = {
          message: error?.message,
          messageTemplate: `Error getting content from contentful after ${attempts} attempts`,
          level: 'Error',
          fields: {
            correlationId: uuidv4(),
            application: 'travel-search',
            environment: getClientEnvironment(),
            frontend: true,
            path: window.location.pathname,
            referer: document.referrer,
            userAgent: navigator.userAgent,
            error: {
              error: error?.name,
              stackTrace: error?.componentStack,
            },
          },
        }
        logger('Error', getClientEnvironment(), payload)
      } else cnt++
    }
  }
}

const Content = async (): Promise<Result> => {
  const urlLocale = getLocaleFromUrlOrQuery()
  const contentfulLocale = getContentfulLocale(urlLocale)

  const contentTaskUrl = new URL('https://cdn.contentful.com/spaces/z860498abl84/environments/master/entries')
  contentTaskUrl.searchParams.append('content_type', 'paxObResource')
  contentTaskUrl.searchParams.append('locale', contentfulLocale)
  contentTaskUrl.searchParams.append('fields.applications', 'travel-search')
  contentTaskUrl.searchParams.append('limit', '300')

  const contentTask = httpClient(contentTaskUrl.toString(), {
    Authorization: 'Bearer cf3eb41c6603c064c84111798dd42d288c8e0262017997f894653da20797e665',
  })

  const _result = await getContentfulData(contentTask)

  const result: Result = {
    content: Object.assign(
      {},
      ..._result.items.map((item: ContentItem) => {
        return { [item.fields.entryTitle]: item.fields.value }
      })
    ),
  }

  return result
}

export default Content

async function httpClient(url: string, headers?: HeadersInit) {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      ...headers,
    },
  }

  let response
  try {
    response = await fetch(url, requestOptions)
    if (!response.ok) {
      let responseObj
      let jsonError
      try {
        responseObj = await response.json()
      } catch (error) {
        jsonError = 'could not parse response body as JSON'
      }
      const errorMessage =
        responseObj?.message || responseObj?.Message || responseObj?.title || jsonError || 'Message did not exist in error response'
      const httpClientError = new Error(errorMessage)
      throw httpClientError
    }
    return await response.json()
  } catch (error: any) {
    // Network errors raised directly from 'response = await fetch(url, requestOptions)', i.e. frontend having problems contacting contentful with fetch in the browser and throws 'Failed to fetch/Load failed'
    // 'Failed to fetch' is window and linux specific
    // 'Load failed' is apple specific
    if (['Failed to fetch', 'Load failed'].includes(error?.message)) throw error
    let errorMessage = error?.message || 'Unknown fetch error'
    const payload: LogProps = {
      message: errorMessage,
      messageTemplate: 'Error getting content from contentful',
      level: 'Error',
      fields: {
        correlationId: uuidv4(),
        application: 'travel-search',
        environment: getClientEnvironment(),
        frontend: true,
        path: window.location.pathname,
        referer: document.referrer,
        userAgent: navigator.userAgent,
        error: {
          error: error?.name,
          stackTrace: error?.componentStack,
        },
      },
    }
    logger('Error', getClientEnvironment(), payload)
  }
}

export interface ContentItem {
  fields: {
    entryTitle: string
    value: any
  }
}

export interface ContentResult {
  items: ContentItem[]
}

export interface Result {
  content: { [key: string]: any }
}
