import { useTranslation } from 'react-i18next'
import { Button, ChevronLeftIcon, TabList, Tabs, TabTrigger } from '@dfds-frontend/compass-ui'

interface Props {
  isOneway: boolean
  onSelected: (oneway: boolean) => void
  isMultiOffer: boolean
  onMultiOfferClicked: () => void
}

const OnewayReturn: React.FC<Props> = ({ isOneway, onSelected, isMultiOffer, onMultiOfferClicked }) => {
  const { t } = useTranslation()

  return (
    <>
      {isMultiOffer && (
        <div className="ts-flex ts-flex-none ts-cursor-pointer ts-mb-dfds-xs ts-truncate [&>button]:!ts-outline-none">
          <Button data-e2e="multiOfferTab" onClick={() => onMultiOfferClicked()} rounded="none" size="md" variant="link">
            <ChevronLeftIcon size="lg" />
            {t('GENERAL-BACK-TO-OFFERS')}
          </Button>
        </div>
      )}
      <Tabs
        onTabChange={(value) => onSelected(value === 'ONEWAY' ? true : false)}
        defaultValue={isOneway ? 'ONEWAY' : 'RETURN'}
        id="returnOneway"
      >
        <TabList label="Oneway/return">
          <TabTrigger data-e2e="returnTab" data-e2e-selected={!isOneway} value="RETURN">
            {t('RETURN')}
          </TabTrigger>
          <TabTrigger data-e2e="oneWayTab" data-e2e-selected={isOneway} value="ONEWAY">
            {t('ONE-WAY')}
          </TabTrigger>
        </TabList>
      </Tabs>
    </>
  )
}

export default OnewayReturn
