import { AvailableTrailer, SelectedVehicle, VehicleType } from '../../Hooks'
import OverlayFooter from '../Shared/OverlayFooter'
import Categories from './Categories'
import Selector from './Selector'

interface Props {
  isVehicleMandatory: boolean
  vehicleTypes: VehicleType[]
  selectedVehicle?: SelectedVehicle | undefined
  availableTrailers: AvailableTrailer[]
  selectedCategory: string | undefined
  selectedTrailer?: AvailableTrailer | undefined
  selectedTrailerNoLongerAvailable?: AvailableTrailer | undefined
  multipleVehiclesChecked: boolean
  multipleVehiclesSupported: boolean
  isLoading: boolean
  dataAttrList: { name: string; value: string }[]
  onFooterClick: () => void
  onSelectVehicle: (vehicleCode: string, count: number, categoryCode?: string, selectedTrailer?: AvailableTrailer | undefined) => void
  onSelectCategory: (categoryCode: string | undefined) => void
  onSelectTrailer: (trailer: AvailableTrailer | undefined) => void
  onCheckMultipleVehicle: (checked: boolean) => void
}
const Vehicles: React.FC<Props> = ({
  isVehicleMandatory,
  vehicleTypes,
  selectedVehicle,
  availableTrailers,
  selectedTrailer,
  selectedTrailerNoLongerAvailable,
  multipleVehiclesChecked,
  multipleVehiclesSupported,
  selectedCategory,
  isLoading,
  dataAttrList,
  onFooterClick,
  onSelectVehicle,
  onSelectCategory,
  onSelectTrailer,
  onCheckMultipleVehicle,
}) => {
  const categories = vehicleTypes?.map((vehicleType) => vehicleType.categoryCode)
  const categoriesUnique = [...new Set(categories)]

  let dataAttrProps = {} as {
    dataAttrName: string
    dataAttrValue: string
  }
  dataAttrList.map((dataAttr) => {
    const key = `data-e2e-${dataAttr?.name}`
    const dataAttrKey = key as keyof {
      dataAttrName: string
      dataAttrValue: string
    }
    dataAttrProps[dataAttrKey] = dataAttr.value?.toString() ?? ''
  })
  const keyLoading = `data-e2e-vehicle-loading`
  const dataAttrKeyLoading = keyLoading as keyof {
    dataAttrName: string
    dataAttrValue: string
  }
  dataAttrProps[dataAttrKeyLoading] = isLoading.toString()

  return (
    <div className={`lg:ts-max-h-[calc(100dvh-160px)] lg:ts-min-w-[512px] lg:ts-overflow-auto`}>
      <Categories
        walkonIsSelected={selectedVehicle?.code === 'NCAR'}
        isVehicleMandatory={isVehicleMandatory}
        categories={categoriesUnique}
        selectedCategoryCode={selectedCategory}
        onSelectCategory={onSelectCategory}
      />
      <Selector
        vehicleTypes={vehicleTypes?.filter((vehicleType) => vehicleType.categoryCode === selectedCategory)}
        selectedVehicle={selectedVehicle}
        selectedCategoryCode={selectedCategory}
        availableTrailers={availableTrailers}
        isLoading={isLoading}
        selectedTrailer={selectedTrailer}
        selectedTrailerNoLongerAvailable={selectedTrailerNoLongerAvailable}
        multipleVehiclesChecked={multipleVehiclesChecked}
        multipleVehiclesSupported={multipleVehiclesSupported}
        onSelectVehicle={onSelectVehicle}
        onSelectTrailer={onSelectTrailer}
        onSelectCategory={onSelectCategory}
        onCheckMultipleVehicle={onCheckMultipleVehicle}
      />
      <OverlayFooter onClick={onFooterClick} />
    </div>
  )
}

export default Vehicles
