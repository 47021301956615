import { useCallback, useEffect, useRef, useState } from 'react'
import SelectField from './Shared/SelectField'
import Routes from './Routes/Routes'
import Vehicles from './Vehicles/Vehicles'
import Passengers from './Passengers/Passengers'
import DateField from './Date/DateField'
import { getAppMinibookId, getDatepickerLocale, toShortDateString } from '../Utils'
import OnewayReturn from './OnewayReturn/OnewayReturn'
import { useTranslation } from 'react-i18next'
import {
  useRoutes,
  useVehicles,
  useTravelDates,
  Route,
  SelectedVehicle,
  SelectedPassengers,
  selectedDates,
  useKeepScrollPosition,
  AvailableTrailer,
  UseVehiclesResult,
} from '../Hooks'
import { LoadDataFromParams } from '../Utils/url-helpers'
import StickyButton from './StickyButton'
import { useWindowWidth2 } from '../Hooks/useWindowWidth2'
import { format } from 'date-fns'
import {
  TrackDates,
  TrackMultipleVehicles,
  TrackOneWay,
  TrackPassengers,
  TrackRoute,
  TrackVehicle,
  TrackingCommand,
  TravelSearchTrackingTypes,
  getTrackingSession,
} from '../Utils/tracking'
import MultiOffer from './MultiOffer/MultiOffer'
import { useTravelSearchConfig } from '../Hooks/useTravelSearchConfig'
import { isFrsRoute, mapToCurrencyToContentfulResource } from '../Utils/frs-helpers'
import Currency from './Currency/Currency'
import { useBookingUrl } from '../Hooks/useBookingUrl'
import React from 'react'
import { CurrencyIcon, FerryReturnIcon, Link, PassengerIcon, RoroFerryIcon } from '@dfds-frontend/compass-ui'
import { VehicleIcon } from './Vehicles/VehicleIcon'
import clsx from 'clsx'
import { DatePicker, DateRangePicker, Skeleton, Spinner } from '@dfds-pax/ui'
import {
  getMultipleVehicleSelectedCount,
  isMultipleVehclesSupportedWithRoute,
  isMultipleVehclesSupportedWithVehicleType,
} from '../Utils/vehicles'
import { motion } from 'framer-motion'
import { isPetsOnlyAllowedInVehicle } from '../Utils/passengers'
import debounce from 'lodash.debounce'

interface Props {
  onReady: () => void
}

const TravelSearch: React.FC<Props> = ({ onReady }) => {
  const stickyTravelSearchRef = useRef<HTMLInputElement>(null)
  const height: number = stickyTravelSearchRef.current?.getBoundingClientRect().height ?? 0

  const [initialLoading, setInitialLoading] = useState<boolean>(true)
  const [initialLoad, setInitialLoad] = useState<boolean>(false)
  const { queryData } = LoadDataFromParams()
  const [activeSelectField, setActiveSelectField] = useState<ActiveSelectField>(ActiveSelectField.NONE)
  const [oneway, setOneway] = useState<boolean | undefined>(queryData.oneWay)
  const [returnFocused, setReturnFocused] = useState<boolean>(false)
  const travelSearchRef = useRef<HTMLDivElement>(null)
  const selectorContainer = useRef<HTMLDivElement>(null)
  const [inView, setInView] = useState<boolean>(false)
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>()
  const [multipleVehiclesChecked, setMultipleVehiclesChecked] = useState<boolean>(false)
  const [multipleVehiclesSupported, setMultipleVehiclesSupported] = useState<boolean>(false)
  const [petsOnlyAllowedInVehicle, setPetsOnlyAllowedInVehicle] = useState<boolean>(false)
  const [selectedRoute, setSelectedRoute] = useState<Route>()
  const [disableOneWay, setDisableOneWay] = useState<boolean>(false)
  const [selectedVehicle, setSelectedVehicle] = useState<SelectedVehicle>()
  const [selectedPassengers, setSelectedPassengers] = useState<SelectedPassengers>()
  const selectedOffercode = ''
  // const [selectedOffercode, setSelectedOffercode] = useState<string>(queryData.offerCode ?? '')
  const [selectedDates, setSelectedDates] = useState<selectedDates>({
    outboundDate: queryData.outboundDate ? new Date(queryData.outboundDate) : undefined,
    homeboundDate: queryData.homeboundDate ? new Date(queryData.homeboundDate) : undefined,
  })
  const [_, setShowReturnChanged] = useState<boolean>(false)
  const [showMultiOffer, setShowMultiOffer] = useState<boolean | undefined>()
  const [showMultiOfferModal, setShowMultiOfferModal] = useState<boolean>(false)
  const [selectedCurrency, setSelectedCurrency] = useState<'EUR' | 'MAD'>('EUR')
  const [minibookId, setMinibookId] = useState<string>(queryData.minibookId ?? getAppMinibookId(window.location.host) ?? '')
  const [selectedTrailerNoLongerAvailable, setSelectedTrailerNoLongerAvailable] = useState<AvailableTrailer | undefined>(undefined)

  const { isMobile, isTablet } = useWindowWidth2()

  const { t } = useTranslation()

  const travelSearchConfigResult = useTravelSearchConfig()

  const routesResult = useRoutes(selectedRoute, oneway, minibookId)
  const vehicleTypesResult = useVehicles(selectedVehicle, selectedPassengers, multipleVehiclesChecked, routesResult, selectedOffercode)
  const travelDatesResult = useTravelDates(
    selectedDates,
    setSelectedDates,
    routesResult,
    vehicleTypesResult,
    selectedCurrency,
    selectedOffercode
  )
  useKeepScrollPosition(isMobile || isTablet, activeSelectField !== ActiveSelectField.NONE)

  useEffect(() => {
    if (
      !travelSearchConfigResult?.isMultiOffer &&
      typeof travelSearchConfigResult?.availableMinibooks === 'string' &&
      !queryData.minibookId
    )
      setMinibookId(getAppMinibookId(window.location.host) ?? travelSearchConfigResult.availableMinibooks)
    setShowMultiOffer(travelSearchConfigResult?.isMultiOffer)
  }, [travelSearchConfigResult?.isMultiOffer])

  useEffect(() => {
    const onScroll = () => {
      const rect = travelSearchRef.current?.getBoundingClientRect()

      if (rect) {
        setInView(rect.bottom < 0)
      }
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.key === 'Escape') {
        setActiveSelectField(ActiveSelectField.NONE)
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  //tracking
  useEffect(() => {
    if (
      travelSearchConfigResult.isLoading === false &&
      travelDatesResult.isLoading === false &&
      routesResult.isLoading === false &&
      vehicleTypesResult.isLoading === false
    ) {
      TrackingCommand(
        TravelSearchTrackingTypes.INITIALIZE,
        routesResult.selectedRoute,
        {
          outboundDate: travelDatesResult.outboundTravelDates?.defaultDate
            ? new Date(travelDatesResult.outboundTravelDates?.defaultDate)
            : undefined,
          homeboundDate:
            !routesResult.isOneway && travelDatesResult?.homeboundTravelDates && travelDatesResult.homeboundTravelDates?.defaultDate
              ? new Date(travelDatesResult.homeboundTravelDates.defaultDate)
              : undefined,
        },
        vehicleTypesResult,
        {
          adults: vehicleTypesResult.selectedPassengers?.adults || 0,
          children: vehicleTypesResult.selectedPassengers?.children || 0,
          childrenAges: vehicleTypesResult.selectedPassengers?.childrenAges || [],
          pets: vehicleTypesResult.selectedPassengers?.pets || 0,
        },
        selectedCurrency,
        routesResult.isOneway
      )
    }
  }, [travelSearchConfigResult.isLoading, routesResult.isLoading, vehicleTypesResult.isLoading, travelDatesResult.isLoading])

  useEffect(() => {
    getTrackingSession() && oneway != undefined && TrackOneWay(oneway)
  }, [oneway])

  useEffect(() => {
    getTrackingSession() && selectedPassengers && TrackPassengers(selectedPassengers)
  }, [selectedPassengers?.adults, selectedPassengers?.children, selectedPassengers?.pets])

  useEffect(() => {
    if (routesResult.selectedRoute?.routeCode && !vehicleTypesResult.isLoading && !routesResult.isLoading) {
      // Check multiple vehicles
      const multipleVehiclesSupportedWithRoute = isMultipleVehclesSupportedWithRoute(routesResult.selectedRoute?.routeCode)
      const multipleVehiclesSupportedWithVehicle = isMultipleVehclesSupportedWithVehicleType(vehicleTypesResult.selectedVehicle.code)
      setMultipleVehiclesSupported(multipleVehiclesSupportedWithRoute && multipleVehiclesSupportedWithVehicle)

      if (!multipleVehiclesSupported || !multipleVehiclesSupportedWithVehicle) {
        setMultipleVehiclesChecked(false)
      }

      // Check pets toggle
      const petsOnlyAllowedInVehicle = isPetsOnlyAllowedInVehicle(routesResult.selectedRoute?.routeCode)
      setPetsOnlyAllowedInVehicle(false)
      setPetsOnlyAllowedInVehicle(petsOnlyAllowedInVehicle)
    }

    if (selectedVehicle?.code !== vehicleTypesResult.selectedVehicle.code && vehicleTypesResult.selectedVehicle.code !== 'NCAR') {
      setSelectedVehicle({ ...vehicleTypesResult.selectedVehicle })

      if (vehicleTypesResult.selectedVehicle.categoryCode && vehicleTypesResult.selectedVehicle.categoryCode !== selectedCategory) {
        setSelectedCategory(undefined)
      }
    }
  }, [routesResult, vehicleTypesResult])

  useEffect(() => {
    if (selectedRoute?.routeCode && !vehicleTypesResult.isLoading && getTrackingSession()) {
      TrackRoute(selectedRoute, vehicleTypesResult)
    }
  }, [selectedRoute, vehicleTypesResult.isLoading])

  useEffect(() => {
    if (selectedVehicle && !initialLoad) {
      setInitialLoad(true)
    }
  }, [selectedVehicle])

  const trackVehicleDebounce = useCallback(
    debounce((vehicleTypesResult: UseVehiclesResult) => TrackVehicle(vehicleTypesResult), 400),
    []
  )

  useEffect(() => {
    initialLoad &&
      getTrackingSession() &&
      !isFrsRoute(routesResult?.selectedRoute?.routeCode ?? '') &&
      selectedVehicle &&
      selectedVehicle.count &&
      vehicleTypesResult &&
      !vehicleTypesResult.isLoading &&
      trackVehicleDebounce(vehicleTypesResult)
  }, [selectedVehicle?.code, selectedVehicle?.count, selectedVehicle?.trailer])

  useEffect(() => {
    getTrackingSession() && selectedDates && TrackDates(selectedDates, false)
  }, [selectedDates])

  useEffect(() => {
    if (
      (selectedRoute && selectedRoute?.returnRequired) ??
      (routesResult.routesApiData && routesResult.routesApiData.categories[0].routes[0].returnRequired) ??
      false
    ) {
      setOneway(false)
      setDisableOneWay(true)
    } else {
      setDisableOneWay(false)
    }
  }, [selectedRoute, routesResult])

  const bookingUrlResult = useBookingUrl(routesResult, vehicleTypesResult, travelDatesResult, selectedCurrency)

  const isLoading: boolean =
    travelSearchConfigResult?.isLoading ||
    travelDatesResult?.isLoading ||
    routesResult?.isLoading ||
    vehicleTypesResult?.isLoading ||
    toShortDateString(travelDatesResult?.selectedOutboundDate) === '-'

  useEffect(() => {
    if (showMultiOffer === true || (showMultiOffer === false && routesResult.isOneway !== undefined)) {
      setInitialLoading(false)
      onReady()
    }
  }, [showMultiOffer, routesResult.isOneway])

  return (
    <div ref={travelSearchRef} id="travel-search-container" className={clsx(`ts-relative ts-p-dfds-s lg:ts-p-dfds-xs`)}>
      <StickyButton
        offersShown={showMultiOffer === true}
        shown={inView && activeSelectField === ActiveSelectField.NONE}
        onClick={() => {
          if (showMultiOffer === true) {
            setShowMultiOfferModal(true)
            return
          }
          travelSearchRef?.current?.scrollIntoView({ behavior: 'smooth' })
        }}
      />

      <motion.div
        ref={stickyTravelSearchRef}
        initial={{
          top: inView ? 0 : -height,
        }}
        animate={{
          top: inView ? 0 : -height,
        }}
        className={clsx(inView && 'lg:ts-bg-dfds-surface-tertiary lg:ts-fixed lg:ts-left-0 lg:ts-right-0 lg:ts-top-0 lg:ts-grid')}
      >
        <div className={clsx(inView && 'md:ts-mx-[40px] xl:ts-max-w-[1200px] xl:ts-mx-auto ts-py-dfds-s')}>
          {!travelSearchConfigResult.isLoading && showMultiOffer && typeof travelSearchConfigResult.availableMinibooks != 'string' ? (
            <>
              <MultiOffer
                offerModalShown={showMultiOfferModal}
                route={travelSearchConfigResult.route || ''}
                description={travelSearchConfigResult.optionalOfferTitle}
                productLists={travelSearchConfigResult.availableMinibooks}
                onProductClicked={(_minibookId: string) => {
                  setMinibookId(_minibookId)
                  setShowMultiOffer(false)
                }}
                onRequestOfferModal={setShowMultiOfferModal}
                inView={inView}
              />
            </>
          ) : (
            <>
              {showMultiOffer !== undefined && routesResult.isOneway !== undefined && !disableOneWay && (
                <div className="ts-mb-dfds-xs ts-w-fit">
                  <OnewayReturn
                    isOneway={routesResult?.isOneway}
                    onSelected={setOneway}
                    isMultiOffer={travelSearchConfigResult.isMultiOffer}
                    onMultiOfferClicked={() => {
                      setShowMultiOffer(true)
                      setShowMultiOfferModal(false)
                    }}
                  />
                </div>
              )}
              {showMultiOffer !== undefined && (
                <div className="ts-grid ts-grid-cols-12 lg:ts-grid-cols-6 lg:ts-grid-rows-1 lg:ts-gap-0 ts-gap-dfds-xs ts-bg-transparent">
                  {/* Routes select field */}
                  <div className="ts-h-[58px] ts-w-full lg:ts-col-auto md:ts-col-span-6 ts-col-span-12 ts-border ts-border-cvi-grey-grey100 lg:ts-border-r-0">
                    <SelectField
                      dataAttrList={[
                        { name: 'selected-route', value: `${routesResult?.selectedRoute?.routeCode}` },
                        { name: 'selected-salesowner', value: `${routesResult?.selectedRoute?.salesOwner}` },
                      ]}
                      disabled={routesResult?.routesApiData?.categories?.reduce((count, current) => count + current.routes.length, 0) === 1}
                      title={t('ROUTE')}
                      isLoading={travelSearchConfigResult?.isLoading || routesResult?.isLoading}
                      isMobile={isMobile}
                      isOpen={activeSelectField === ActiveSelectField.ROUTE}
                      fieldName="route"
                      value={routesResult.selectedRoute?.name}
                      icon={routesResult?.isOneway ? <RoroFerryIcon size="md" /> : <FerryReturnIcon size="md" />}
                      children={
                        <Routes
                          routeCategories={routesResult.routesApiData?.categories}
                          onSelectRoute={(route) => {
                            setSelectedRoute({
                              routeCode: route.routeCode,
                              salesOwner: route.salesOwner,
                              name: route?.name,
                              returnRequired: route.returnRequired,
                            })
                            setActiveSelectField(ActiveSelectField.NONE)
                          }}
                        />
                      }
                      onClick={(clickedOutside: boolean) => {
                        if (clickedOutside) {
                          setActiveSelectField(ActiveSelectField.NONE)
                        } else {
                          !routesResult.isLoading && setActiveSelectField(ActiveSelectField.ROUTE)
                        }
                      }}
                      onClose={() => {
                        activeSelectField === ActiveSelectField.ROUTE && setActiveSelectField(ActiveSelectField.NONE)
                      }}
                    />
                  </div>

                  {/* Vehicles select field */}
                  <div
                    className={clsx(
                      'ts-h-[58px] ts-w-full ts-justify-center md:ts-col-span-6 ts-col-span-12 lg:ts-col-auto ts-border ts-border-cvi-grey-grey100 lg:ts-border-r-0',
                      isFrsRoute(routesResult?.selectedRoute?.routeCode ?? '') && 'ts-hidden'
                    )}
                  >
                    <SelectField
                      dataAttrList={[
                        { name: 'selected-vehicle', value: `${vehicleTypesResult?.selectedVehicle?.code}` },
                        { name: 'selected-trailer', value: `${vehicleTypesResult?.selectedVehicle?.selectedTrailer?.code}` },
                      ]}
                      disabled={
                        vehicleTypesResult?.vehicleApiData?.vehicleTypes?.reduce((count, current) => count + current.code.length, 0) ===
                          1 ||
                        (vehicleTypesResult?.vehicleApiData?.vehicleTypes?.reduce((count, current) => count + current.code.length, 0) ===
                          0 &&
                          vehicleTypesResult.selectedVehicle.code === 'NCAR')
                      }
                      title={t('VEHICLE')}
                      isLoading={travelSearchConfigResult?.isLoading || routesResult?.isLoading || vehicleTypesResult.isLoading}
                      isMobile={isMobile}
                      isOpen={activeSelectField === ActiveSelectField.VEHICLE}
                      fieldName="vehicle"
                      value={
                        vehicleTypesResult.selectedVehicle.selectedTrailer
                          ? `${getMultipleVehicleSelectedCount(vehicleTypesResult?.selectedVehicle?.count)}${t(`VEHICLE-NAME-${vehicleTypesResult?.selectedVehicle?.code}`)} + ${getMultipleVehicleSelectedCount(vehicleTypesResult?.selectedVehicle?.count)} ${t(
                              `TRAILER-NAME-${vehicleTypesResult?.selectedVehicle?.selectedTrailer?.code}`
                            )}`
                          : `${getMultipleVehicleSelectedCount(vehicleTypesResult?.selectedVehicle?.count)}${t(`VEHICLE-NAME-${vehicleTypesResult?.selectedVehicle?.code}`)}`
                      }
                      icon={
                        vehicleTypesResult.selectedVehicle?.code && (
                          <VehicleIcon code={vehicleTypesResult?.selectedVehicle?.code} size="md" />
                        )
                      }
                      visibleRows={vehicleTypesResult.selectedVehicle && vehicleTypesResult.selectedVehicle.selectedTrailer ? 2 : 1}
                      children={
                        <Vehicles
                          dataAttrList={[
                            { name: 'selected-vehicle', value: `${vehicleTypesResult?.selectedVehicle?.code}` },
                            { name: 'selected-trailer', value: `${vehicleTypesResult?.selectedVehicle?.selectedTrailer?.code}` },
                          ]}
                          isLoading={travelSearchConfigResult?.isLoading || routesResult?.isLoading || vehicleTypesResult.isLoading}
                          isVehicleMandatory={vehicleTypesResult.vehicleApiData?.isVehicleMandatory || false}
                          vehicleTypes={vehicleTypesResult.vehicleApiData?.vehicleTypes}
                          selectedVehicle={vehicleTypesResult.selectedVehicle}
                          selectedTrailer={vehicleTypesResult?.selectedVehicle?.selectedTrailer}
                          selectedTrailerNoLongerAvailable={selectedTrailerNoLongerAvailable}
                          selectedCategory={selectedCategory}
                          availableTrailers={
                            vehicleTypesResult.vehicleApiData?.vehicleTypes.find(
                              (x: any) => x.code === vehicleTypesResult.selectedVehicle.code
                            )?.availableTrailers || []
                          }
                          multipleVehiclesChecked={multipleVehiclesChecked}
                          multipleVehiclesSupported={multipleVehiclesSupported}
                          onCheckMultipleVehicle={(multipleVehicleChecked: boolean) => {
                            if (!multipleVehicleChecked && selectedVehicle) {
                              // Reset vehicle count
                              setSelectedVehicle({ ...selectedVehicle, count: 1 })
                            }
                            setMultipleVehiclesChecked(multipleVehicleChecked)
                            TrackMultipleVehicles(multipleVehicleChecked)
                          }}
                          onSelectCategory={(categoryCode) => {
                            if (categoryCode === 'NCAR') {
                              setSelectedVehicle({
                                code: 'NCAR',
                                trailer: undefined,
                                categoryCode: undefined,
                                count: 0,
                              })
                              setMultipleVehiclesChecked(false)
                              setMultipleVehiclesSupported(false)
                            } else setSelectedCategory(categoryCode)
                          }}
                          onSelectVehicle={(vehicle, count, categoryCode, activeTrailer) => {
                            const availableTrailers =
                              vehicleTypesResult.vehicleApiData?.vehicleTypes.find((x: any) => x.code === vehicle)?.availableTrailers || []
                            let trailer = vehicleTypesResult?.selectedVehicle?.selectedTrailer
                            if (
                              availableTrailers?.length > 0 &&
                              !availableTrailers.some((x) => x.code === vehicleTypesResult?.selectedVehicle?.selectedTrailer?.code)
                            ) {
                              setSelectedTrailerNoLongerAvailable(vehicleTypesResult?.selectedVehicle?.selectedTrailer)
                              trailer = undefined
                            } else setSelectedTrailerNoLongerAvailable(undefined)
                            setSelectedVehicle({
                              code: vehicle,
                              categoryCode: categoryCode,
                              trailer: trailer ?? activeTrailer,
                              count: count,
                            })

                            if (selectedRoute?.routeCode) {
                              const isMultipleVehclesSupportedWithVehicle = isMultipleVehclesSupportedWithVehicleType(vehicle)
                              const isMultipleVehclesSupportedWithSelectedRoute = isMultipleVehclesSupportedWithRoute(
                                selectedRoute?.routeCode
                              )
                              setMultipleVehiclesSupported(
                                isMultipleVehclesSupportedWithVehicle && isMultipleVehclesSupportedWithSelectedRoute
                              )
                            }
                          }}
                          onSelectTrailer={(trailer) => {
                            setSelectedTrailerNoLongerAvailable(undefined)
                            setSelectedVehicle({
                              code: vehicleTypesResult?.selectedVehicle?.code,
                              trailer: trailer,
                              count: selectedVehicle?.count ?? 1,
                            })
                          }}
                          onFooterClick={() => setActiveSelectField(ActiveSelectField.NONE)}
                        />
                      }
                      onClick={(clickedOutside: boolean) => {
                        if (clickedOutside) {
                          setActiveSelectField(ActiveSelectField.NONE)
                        } else {
                          !routesResult?.isLoading && !vehicleTypesResult.isLoading && setActiveSelectField(ActiveSelectField.VEHICLE)
                        }
                      }}
                      onClose={() => {
                        activeSelectField === ActiveSelectField.VEHICLE && setActiveSelectField(ActiveSelectField.NONE)
                      }}
                    />
                  </div>

                  {/* Passengers select field */}
                  <div
                    className={clsx(
                      'ts-h-[58px] ts-w-full ts-col-span-12 md:ts-col-span-6 lg:ts-col-auto ts-border ts-border-cvi-grey-grey100 lg:ts-border-r-0',
                      isFrsRoute(routesResult?.selectedRoute?.routeCode ?? '') && 'ts-hidden'
                    )}
                  >
                    <SelectField
                      dataAttrList={[
                        { name: 'selected-adults', value: `${vehicleTypesResult?.selectedPassengers?.adults}` },
                        { name: 'selected-children', value: `${vehicleTypesResult?.selectedPassengers?.children}` },
                        { name: 'child-ages', value: `${vehicleTypesResult?.selectedPassengers?.childrenAges?.join(',')}` },
                        { name: 'selected-pets', value: `${vehicleTypesResult?.selectedPassengers?.pets}` },
                      ]}
                      title={`${t('PASSENGERS')} & ${t('PETS')}`}
                      isLoading={travelSearchConfigResult?.isLoading || routesResult?.isLoading || vehicleTypesResult.isLoading}
                      isMobile={isMobile}
                      isOpen={activeSelectField === ActiveSelectField.PASSENGER}
                      fieldName="passenger"
                      value={
                        vehicleTypesResult?.selectedPassengers?.pets && vehicleTypesResult?.selectedPassengers?.pets > 0
                          ? `${vehicleTypesResult.numberOfPassengers} + ${t('PETS')}`
                          : vehicleTypesResult.numberOfPassengers
                      }
                      icon={<PassengerIcon size="md" />}
                      visibleRows={vehicleTypesResult.selectedPassengers?.adults && vehicleTypesResult.selectedPassengers.pets ? 2 : 1}
                      children={
                        <Passengers
                          dataAttrList={[
                            { name: 'selected-adults', value: `${vehicleTypesResult?.selectedPassengers?.adults}` },
                            { name: 'selected-children', value: `${vehicleTypesResult?.selectedPassengers?.children}` },
                            { name: 'child-ages', value: `${vehicleTypesResult?.selectedPassengers?.childrenAges?.join(',')}` },
                            { name: 'selected-pets', value: `${vehicleTypesResult?.selectedPassengers?.pets}` },
                          ]}
                          isLoading={travelSearchConfigResult?.isLoading || routesResult?.isLoading || vehicleTypesResult.isLoading}
                          adults={vehicleTypesResult?.selectedPassengers?.adults ?? 1}
                          children={vehicleTypesResult?.selectedPassengers?.children ?? 0}
                          childrenAges={vehicleTypesResult?.selectedPassengers?.childrenAges}
                          pets={vehicleTypesResult?.selectedPassengers?.pets ?? 0}
                          maxPax={vehicleTypesResult?.maxNumberOfPassengers}
                          maxPets={vehicleTypesResult?.vehicleApiData?.maxNumberOfPets}
                          petsAllowed={vehicleTypesResult.petsAllowed}
                          petsOnlyAllowedInVehicle={petsOnlyAllowedInVehicle}
                          selectedRoute={routesResult?.selectedRoute?.name ?? ''}
                          onQuantityChange={(adults: number, children: number, pets: number, childrenAges: number[]) => {
                            if (childrenAges && childrenAges.length < children) {
                              childrenAges = [...childrenAges, 8]
                            } else if (childrenAges && childrenAges.length > children) {
                              childrenAges = childrenAges.slice(0, children)
                            }
                            setSelectedPassengers({
                              adults,
                              children,
                              pets,
                              childrenAges: childrenAges || [],
                            })
                          }}
                          onFooterClick={() => setActiveSelectField(ActiveSelectField.NONE)}
                        />
                      }
                      onClick={(clickedOutside: boolean) => {
                        if (clickedOutside) {
                          setSelectedTrailerNoLongerAvailable(undefined)
                          setActiveSelectField(ActiveSelectField.NONE)
                        } else {
                          !routesResult?.isLoading && !vehicleTypesResult.isLoading && setActiveSelectField(ActiveSelectField.PASSENGER)
                        }
                      }}
                      onClose={() => {
                        activeSelectField === ActiveSelectField.PASSENGER && setActiveSelectField(ActiveSelectField.NONE)
                      }}
                    />
                  </div>

                  {/* Currency select field */}
                  <div
                    className={clsx(
                      'ts-h-[58px] ts-w-full ts-col-span-12 md:ts-col-span-6 lg:ts-col-auto ts-border ts-border-cvi-grey-grey100',
                      !isFrsRoute(routesResult?.selectedRoute?.routeCode ?? '') && 'ts-hidden'
                    )}
                  >
                    {initialLoading ? (
                      <Skeleton className="ts-h-full ts-w-full" square />
                    ) : (
                      <SelectField
                        dataAttrList={[{ name: 'selected-currency', value: `${selectedCurrency}` }]}
                        disabled={!isFrsRoute(routesResult.selectedRoute?.routeCode ?? '')}
                        title={t('CURRENCY')}
                        isLoading={travelSearchConfigResult?.isLoading || routesResult?.isLoading}
                        isMobile={isMobile}
                        isOpen={activeSelectField === ActiveSelectField.CURRENCY}
                        fieldName="currency"
                        value={mapToCurrencyToContentfulResource(selectedCurrency, t)}
                        icon={<CurrencyIcon size="md" />}
                        children={
                          <Currency
                            selectedCurrency={selectedCurrency}
                            onSelectCurrency={(currency) => {
                              setSelectedCurrency(currency)
                              setActiveSelectField(ActiveSelectField.NONE)
                            }}
                          />
                        }
                        onClick={(clickedOutside: boolean) => {
                          if (clickedOutside) {
                            setActiveSelectField(ActiveSelectField.NONE)
                          } else {
                            !routesResult.isLoading && setActiveSelectField(ActiveSelectField.CURRENCY)
                          }
                        }}
                        onClose={() => {
                          activeSelectField === ActiveSelectField.ROUTE && setActiveSelectField(ActiveSelectField.NONE)
                        }}
                      />
                    )}
                  </div>

                  <div className="ts-h-[58px] ts-w-full ts-col-span-12 md:ts-col-span-6 lg:ts-col-span-2">
                    <DateField
                      dataAttrList={[
                        {
                          name: 'selected-outbound-date',
                          value:
                            travelDatesResult?.selectedOutboundDate && `${format(travelDatesResult?.selectedOutboundDate, 'yyyy-MM-dd')}`,
                        },
                        {
                          name: 'selected-homebound-date',
                          value:
                            travelDatesResult?.selectedHomeboundDate && `${format(travelDatesResult?.selectedHomeboundDate, 'yyyy-MM-dd')}`,
                        },
                      ]}
                      isLoading={
                        travelSearchConfigResult?.isLoading ||
                        routesResult?.isLoading ||
                        vehicleTypesResult.isLoading ||
                        travelDatesResult.isLoading
                      }
                      disabled={
                        routesResult?.isOneway
                          ? travelDatesResult?.outboundTravelDates?.dates?.fromDate ===
                            travelDatesResult?.outboundTravelDates?.dates?.toDate
                          : travelDatesResult?.outboundTravelDates?.dates?.fromDate ===
                              travelDatesResult?.outboundTravelDates?.dates?.toDate &&
                            travelDatesResult?.homeboundTravelDates?.dates?.fromDate ===
                              travelDatesResult?.homeboundTravelDates?.dates?.toDate
                      }
                      showFooter={false}
                      returnFocused={returnFocused}
                      isOpen={activeSelectField === ActiveSelectField.DATE}
                      isOneway={routesResult?.isOneway}
                      fieldName="date"
                      selectedOutboundDate={travelDatesResult.selectedOutboundDate}
                      selectedHomeboundDate={travelDatesResult.selectedHomeboundDate}
                      containerRef={selectorContainer}
                      onClick={(clickedOutside: boolean, clickedReturnField?: boolean) => {
                        if (
                          routesResult?.isOneway
                            ? !(
                                travelDatesResult?.outboundTravelDates?.dates?.fromDate ===
                                travelDatesResult?.outboundTravelDates?.dates?.toDate
                              )
                            : !(
                                travelDatesResult?.outboundTravelDates?.dates?.fromDate ===
                                  travelDatesResult?.outboundTravelDates?.dates?.toDate &&
                                travelDatesResult?.homeboundTravelDates?.dates?.fromDate ===
                                  travelDatesResult?.homeboundTravelDates?.dates?.toDate
                              )
                        ) {
                          if (oneway && clickedReturnField) {
                            setShowReturnChanged(true)
                            return
                          }

                          if (clickedOutside) {
                            setActiveSelectField(ActiveSelectField.NONE)
                            setReturnFocused(false)
                          } else {
                            !routesResult?.isLoading &&
                              !vehicleTypesResult.isLoading &&
                              !travelDatesResult.isLoading &&
                              setActiveSelectField(ActiveSelectField.DATE)
                          }
                        }
                      }}
                      onClose={() => {
                        activeSelectField === ActiveSelectField.DATE && setActiveSelectField(ActiveSelectField.NONE)
                        setReturnFocused(false)
                      }}
                    >
                      {routesResult?.isOneway && (
                        <DatePicker
                          newStyles={true}
                          locale={getDatepickerLocale()}
                          travelDates={travelDatesResult?.outboundTravelDates}
                          selectedDate={travelDatesResult.selectedOutboundDate}
                          onDateChanged={(date: Date | undefined) => {
                            if (date) {
                              setSelectedDates({ outboundDate: date, homeboundDate: undefined })
                              setActiveSelectField(ActiveSelectField.NONE)
                            }
                          }}
                        />
                      )}

                      {!routesResult?.isOneway && (
                        <DateRangePicker
                          newStyles={true}
                          locale={getDatepickerLocale()}
                          returnFocused={returnFocused}
                          isLoading={travelDatesResult.isLoading}
                          outboundTravelDates={travelDatesResult?.outboundTravelDates}
                          homeboundTravelDates={travelDatesResult?.homeboundTravelDates}
                          selectedDates={{
                            from: travelDatesResult.selectedOutboundDate,
                            to: travelDatesResult.selectedHomeboundDate,
                          }}
                          outboundDateChanged={(date: Date) => {
                            setSelectedDates({ outboundDate: date, homeboundDate: date })
                            setReturnFocused(true)
                          }}
                          returnDateChanged={(date: Date) => {
                            setSelectedDates({ outboundDate: travelDatesResult.selectedOutboundDate, homeboundDate: date })
                            setReturnFocused(false)
                            setActiveSelectField(ActiveSelectField.NONE)
                          }}
                          onClose={() => {
                            setActiveSelectField(ActiveSelectField.NONE)
                            setReturnFocused(false)
                          }}
                        />
                      )}
                    </DateField>
                  </div>
                  <div className="ts-col-span-12 lg:ts-col-span-1 dfds-orange-button">
                    {bookingUrlResult.isLoading || isLoading ? (
                      <div className="ts-flex ts-font-medium ts-items-center ts-justify-center ts-h-[54px] md:ts-h-[58px] ts-w-full ts-bg-dfds-status-warning ts-text-white ts-cursor-not-allowed">
                        <Spinner height={30} width={30} className="ts-animate-spin ts-duration-700" />
                      </div>
                    ) : (
                      <Link
                        onClick={() =>
                          TrackingCommand(
                            TravelSearchTrackingTypes.SUBMIT,
                            routesResult.selectedRoute,
                            {
                              outboundDate: travelDatesResult.selectedOutboundDate,
                              homeboundDate: travelDatesResult.selectedHomeboundDate,
                            },
                            vehicleTypesResult,
                            {
                              adults: vehicleTypesResult.selectedPassengers?.adults || 0,
                              children: vehicleTypesResult.selectedPassengers?.children || 0,
                              childrenAges: vehicleTypesResult.selectedPassengers?.childrenAges || [],
                              pets: vehicleTypesResult.selectedPassengers?.pets || 0,
                            },
                            selectedCurrency,
                            routesResult.isOneway
                          )
                        }
                        data-e2e="booking-link"
                        href={bookingUrlResult.url}
                        size="lg"
                        stretch
                        variant="primary"
                      >
                        <span className="ts-max-w-[200px] ts-truncate">{t('SEE-PRICES')}</span>
                      </Link>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </motion.div>
    </div>
  )
}

export default TravelSearch

enum ActiveSelectField {
  ROUTE,
  VEHICLE,
  PASSENGER,
  DATE,
  CURRENCY,
  NONE,
}
