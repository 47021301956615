import TravelSearch from './Components/TravelSearch'
import i18n from 'i18next'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import { getLocaleFromUrlOrQuery } from './Utils/common'
import { useEffect, useState } from 'react'
import Content from './Utils/content'
import '@dfds-pax/ui/styles.css'
import { TravelSearchSkeleton } from './Components/Shared/TravelSearchSkeleton'
import clsx from 'clsx'
import { TravelSearchOfferSkeleton } from './Components/Shared/TravelSearchOfferSkeleton'

const AppWithContent = ({ hasOffer }: { hasOffer: boolean | undefined }) => {
  const [ready, setReady] = useState<boolean>(false)
  const [i18Instance, seti18Instance] = useState<typeof i18n>()

  const locale = getLocaleFromUrlOrQuery()

  useEffect(() => {
    ;(async () => {
      const { content } = await Content()

      if (content) {
        const _i18Instance = i18n.createInstance()
        _i18Instance.use(initReactI18next).init({
          debug: false,
          lng: locale,
          fallbackLng: 'en',
          interpolation: {
            escapeValue: false,
          },
          resources: {},
        })
        _i18Instance.addResourceBundle(locale.substring(0, 2), 'translation', content)
        seti18Instance(_i18Instance)
      }
    })()
  }, [])

  return (
    <div id="travel-search">
      <div className={clsx(ready ? 'ts-hidden' : 'ts-block')}>{hasOffer ? <TravelSearchOfferSkeleton /> : <TravelSearchSkeleton />}</div>
      {i18Instance && (
        <I18nextProvider i18n={i18Instance}>
          {i18Instance && (
            <div className={clsx(ready ? 'ts-block' : 'ts-hidden')}>
              <TravelSearch onReady={() => setReady(true)} />
            </div>
          )}
        </I18nextProvider>
      )}
    </div>
  )
}

export default AppWithContent
