import Dimensions from './Dimensions'
import { TrailerIcon, VehicleIcon } from './VehicleIcon'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { AvailableTrailer, SelectedVehicle, VehicleType, useWindowWidth } from '../../Hooks'
import { ArrowBackIcon, ChevronDownIcon, NoIcon, YesIcon } from '@dfds-frontend/compass-ui'
import { Toggle } from '../Shared/Toggle'
import Counter from './Counter'
import { useFieldArray, useForm } from 'react-hook-form'
import { isMobile, isTablet } from 'react-device-detect'
import clsx from 'clsx'

interface Props {
  vehicleTypes: VehicleType[]
  selectedVehicle?: SelectedVehicle | undefined
  isLoading: boolean
  availableTrailers: AvailableTrailer[]
  selectedTrailer?: AvailableTrailer | undefined
  selectedTrailerNoLongerAvailable?: AvailableTrailer | undefined
  multipleVehiclesChecked: boolean
  multipleVehiclesSupported: boolean
  selectedCategoryCode: string | undefined
  onSelectVehicle: (vehicleCode: string, count: number, categoryCode?: string, selectedTrailer?: AvailableTrailer | undefined) => void
  onSelectTrailer: (trailer: AvailableTrailer | undefined) => void
  onSelectCategory: (categoryCode: string | undefined) => void
  onCheckMultipleVehicle: (checked: boolean) => void
}

const Selector: React.FC<Props> = ({
  vehicleTypes,
  selectedVehicle,
  availableTrailers,
  selectedTrailer,
  selectedTrailerNoLongerAvailable,
  selectedCategoryCode,
  multipleVehiclesChecked,
  multipleVehiclesSupported,
  onSelectVehicle,
  onSelectTrailer,
  onSelectCategory,
  onCheckMultipleVehicle,
}) => {
  const [showTrailerSelector, setShowTrailerSelector] = useState<boolean>(false)
  const { widthSmallerThan1025 } = useWindowWidth()
  // const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { t } = useTranslation()
  const trailerDropdownRef = useRef<HTMLDivElement>(null)

  const { register, control, setValue, watch } = useForm()
  const {} = useFieldArray({
    control,
    name: 'vehicleCount',
  })

  const counts = watch('vehicleCount')

  const resetValues = (vehicleCode: string) => {
    // Reset old values
    vehicleTypes &&
      vehicleTypes.forEach((type: VehicleType) => {
        if (type.code !== vehicleCode) {
          const currentValue = counts[type.code].value
          if (!currentValue || currentValue > 0) {
            setValue(`vehicleCount.${type.code}.value`, 0)
          }
        }
      })
  }

  useEffect(() => {
    if (!multipleVehiclesSupported && selectedVehicle?.code !== 'NCAR') {
      if (selectedVehicle?.code) {
        setValue(`vehicleCount.${selectedVehicle?.code}.value`, 1)
        onSelectVehicle(selectedVehicle.code, 1, selectedVehicle.categoryCode, selectedTrailer)
      }
    }
  }, [multipleVehiclesSupported])

  return (
    <>
      {vehicleTypes?.length > 0 && (
        <>
          {multipleVehiclesChecked && multipleVehiclesSupported ? (
            <ul data-travel-search-child className="ts-border-0 ts-border-solid ts-list-none">
              <div
                data-e2e="vehicle-categories-selector"
                className="ts-flex ts-border-b ts-border-solid ts-border-dfds-divider-dark ts-flex-row ts-gap-dfds-xs ts-cursor-pointer ts-items-center ts-bg-dfds-surface-light ts-p-dfds-s"
                onClick={() => onSelectCategory(undefined)}
              >
                <ArrowBackIcon className="ts-text-cvi-brand-brand900 ts-text-[24px]" />
                <label className="ts-text-cvi-brand-brand900 ts-cursor-pointer ts-text-base ts-font-medium">
                  {t('VEHICLE-CATEGORIES')}
                </label>
              </div>
              {vehicleTypes.map((vehicleType) => (
                <li
                  data-e2e={`vehicle-type-${vehicleType.code}`}
                  key={vehicleType.code}
                  className={`ts-m-0 ts-list-none ts-flex ts-gap-dfds-s ts-min-h-[90px] ts-items-center ts-border-0 ts-border-b ts-border-solid ts-border-dfds-divider-dark ts-p-dfds-s
              `}
                >
                  <div className="ts-flex ts-flex-col">
                    <label className="ts-text-cvi-brand-brand900 ts-text-base ts-font-medium">
                      {t(`VEHICLE-NAME-${vehicleType.code}`)}
                    </label>
                    <div className="ts-text-sm ts-text-cvi-grey-grey700">{t(`VEHICLE-DESCRIPTION-${vehicleType.code}`)}</div>
                  </div>
                  <div className="ts-ml-auto">
                    <Counter
                      {...register(`vehicleCount.${vehicleType.code}.value`, { valueAsNumber: true })}
                      e2eAttribute="vehiclesCounter"
                      defaultValue={selectedVehicle?.code === vehicleType.code ? selectedVehicle?.count ?? 1 : 0}
                      downDisabled={counts[vehicleType.code].value === 0 || selectedVehicle?.code !== vehicleType.code}
                      upDisabled={
                        (selectedVehicle &&
                          selectedVehicle?.count > 0 &&
                          selectedVehicle?.categoryCode === vehicleType.categoryCode &&
                          selectedVehicle.code !== vehicleType.code) ||
                        counts[vehicleType.code].value === 9 ||
                        false
                      }
                      onValueIncrease={() => {
                        const currentValue: number =
                          counts[vehicleType.code].value ?? selectedVehicle?.code === vehicleType.code
                            ? counts[vehicleType.code].value ?? 1
                            : 0
                        setValue(`vehicleCount.${vehicleType.code}.value`, typeof currentValue != 'undefined' ? currentValue + 1 : 1)
                        resetValues(vehicleType.code)
                        onSelectVehicle(vehicleType.code, currentValue + 1, vehicleType.categoryCode, selectedTrailer)
                      }}
                      onValueDecrease={() => {
                        const currentValue =
                          counts[vehicleType.code].value ?? selectedVehicle?.code === vehicleType.code
                            ? counts[vehicleType.code].value ?? 1
                            : 0
                        setValue(`vehicleCount.${vehicleType.code}.value`, typeof currentValue != 'undefined' ? currentValue - 1 : 0)
                        resetValues(vehicleType.code)
                        onSelectVehicle(vehicleType.code, currentValue - 1, vehicleType.categoryCode, selectedTrailer)
                      }}
                    />
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <ul data-travel-search-child className="ts-border-0 ts-border-solid ts-list-none">
              <div
                data-e2e="vehicle-categories-selector"
                className="ts-flex ts-border-b ts-border-solid ts-border-dfds-divider-dark ts-flex-row ts-gap-dfds-xs ts-cursor-pointer ts-items-center ts-bg-dfds-surface-light ts-p-dfds-s"
                onClick={() => onSelectCategory(undefined)}
              >
                <ArrowBackIcon className="ts-text-cvi-brand-brand900 ts-text-[24px]" />
                <label className="ts-text-cvi-brand-brand900 ts-text-base ts-font-medium ts-cursor-pointer">
                  {t('VEHICLE-CATEGORIES')}
                </label>
              </div>
              {vehicleTypes.map((vehicleType) => (
                <li
                  data-e2e={`vehicle-type-${vehicleType.code}`}
                  key={vehicleType.code}
                  className={clsx(
                    `ts-p-0 ts-m-0 ts-list-none ts-flex ts-gap-dfds-s ts-h-[90px] ts-cursor-pointer ts-items-center ts-border-0 ts-border-b ts-border-solid ts-border-dfds-divider-dark ts-px-dfds-s`,
                    vehicleType.code === selectedVehicle?.code
                      ? 'ts-bg-cvi-states-selected'
                      : `${!isMobile && !isTablet && 'hover:ts-bg-cvi-states-hover'}`
                  )}
                  onClick={() => {
                    onSelectVehicle(vehicleType.code, 1, selectedCategoryCode)
                  }}
                >
                  <div className="ts-text-cvi-brand-brand900 ts-cursor-pointer ts-max-w-[46px] ts-min-w-[46px]">
                    <Dimensions height={vehicleType.height} length={vehicleType.length}>
                      <VehicleIcon code={vehicleType.code} size="lg" />
                    </Dimensions>
                  </div>
                  <div className="ts-flex ts-flex-col">
                    <label className="ts-text-cvi-brand-brand900 ts-text-base ts-font-medium ts-cursor-pointer">
                      {t(`VEHICLE-NAME-${vehicleType.code}`)}
                    </label>
                    <div className="ts-text-sm ts-text-cvi-grey-grey700 ts-cursor-pointer">
                      {t(`VEHICLE-DESCRIPTION-${vehicleType.code}`)}
                    </div>
                  </div>
                  {vehicleType.code === selectedVehicle?.code && (
                    <div className="ts-ml-auto ts-text-cvi-brand-brand900">
                      <YesIcon size="md" />
                    </div>
                  )}
                </li>
              ))}
            </ul>
          )}
        </>
      )}

      {selectedCategoryCode && (
        <>
          <div className="ts-bg-dfds-surface-light ts-p-dfds-s">
            <div className="ts-text-cvi-brand-brand900 ts-font-medium">{t('TRAILER-CARAVAN')}</div>
            <div
              className={`ts-mt-dfds-xxs ts-border ts-border-solid ts-border-cvi-brand-brand900 ts-bg-dfds-surface-light ${
                selectedTrailerNoLongerAvailable && 'ts-border-dfds-status-alert'
              }`}
            >
              <div
                data-e2e="trailer-selector"
                ref={trailerDropdownRef}
                className="ts-flex ts-flex-row ts-cursor-pointer ts-bg-dfds-surface-light ts-p-dfds-xs"
                onClick={() => {
                  setShowTrailerSelector(!showTrailerSelector)
                  if (widthSmallerThan1025) {
                    setTimeout(() => {
                      trailerDropdownRef.current?.firstElementChild?.scrollIntoView({ behavior: 'smooth' })
                    }, 1)
                  }
                }}
              >
                <div className="ts-text-cvi-brand-brand900 ts-font-medium ts-flex ts-flex-row ts-gap-dfds-xs ts-items-center">
                  <TrailerIcon code={selectedTrailer?.code} />
                  <span data-e2e-sel-trailer={selectedTrailer ? selectedTrailer.code : 'NO-TRAILER'}>
                    {selectedTrailer
                      ? `${t(`TRAILER-NAME-${selectedTrailer.code}`)} < ${selectedTrailer.height}m x ${selectedTrailer.length}m`
                      : t('NO-TRAILER-CARAVAN')}
                  </span>
                </div>
                <motion.div className="ts-ml-auto" animate={{ rotate: showTrailerSelector ? -180 : 0 }}>
                  <ChevronDownIcon size="md" />
                </motion.div>
              </div>
              {showTrailerSelector && (
                <ul data-travel-search-child className={`${!showTrailerSelector} ts-p-0 ts-m-0 ts-border-0 ts-border-solid ts-list-none`}>
                  <>
                    <li
                      data-e2e="no-trailer"
                      className={clsx(
                        `ts-p-0 ts-m-0 ts-flex ts-flex-row ts-items-center ts-gap-dfds-xs ts-px-dfds-xs ts-border-0 ts-list-none ts-border-b ts-border-t ts-border-solid ts-cursor-pointer ts-border-dfds-divider-dark ts-py-dfds-xs`,
                        !selectedTrailer ? 'ts-bg-cvi-states-selected' : `${!isMobile && !isTablet && 'hover:ts-bg-cvi-states-hover'}`
                      )}
                      key="no-trailer"
                      onClick={() => {
                        onSelectTrailer(undefined)
                        setShowTrailerSelector(false)
                      }}
                    >
                      <NoIcon size="md" />
                      <div className="ts-font-medium ts-text-cvi-brand-brand900">{t('NO-TRAILER-CARAVAN')}</div>
                      {!selectedTrailer?.code && (
                        <div className="ts-ml-auto ts-text-cvi-brand-brand900">
                          <YesIcon size="md" />
                        </div>
                      )}
                    </li>

                    {availableTrailers.map((trailer) => (
                      <li
                        data-e2e={`trailer-${trailer.code}`}
                        className={`ts-p-0 ts-m-0 ts-border-0  ts-flex ts-flex-row ts-px-dfds-xs ts-items-center ts-gap-dfds-xs ts-list-none ts-cursor-pointer ts-border-b ts-border-solid ts-border-dfds-divider-dark ts-py-dfds-xs
                          ${selectedTrailer === trailer ? 'ts-bg-cvi-states-selected' : `ts-bg-dfds-surface-light ${!isMobile && !isTablet && 'hover:ts-bg-cvi-states-hover'}`}
                        `}
                        key={trailer.code}
                        onClick={() => {
                          onSelectTrailer(trailer)
                          setShowTrailerSelector(false)
                        }}
                      >
                        <TrailerIcon code={trailer.code} />
                        <div className="ts-font-medium ts-text-cvi-brand-brand900">
                          {`${t(`TRAILER-NAME-${trailer.code}`)} <`} {trailer.height}m x {trailer.length}m
                        </div>
                        {trailer.code === selectedTrailer?.code && (
                          <div className="ts-ml-auto ts-text-cvi-brand-brand900">
                            <YesIcon size="md" />
                          </div>
                        )}
                      </li>
                    ))}
                  </>
                </ul>
              )}
            </div>
            {selectedTrailerNoLongerAvailable && (
              <label className="ts-bg-dfds-surface-light ts-font-light ts-text-xs ts-text-dfds-status-alert">
                <span data-e2e="incompatible-trailer">
                  {t('INCOMPATIBLE-CAR-TRAILER-ERROR-MESSAGE', {
                    trailerType: `${t(`TRAILER-NAME-${selectedTrailerNoLongerAvailable.code}`)} < ${selectedTrailerNoLongerAvailable.height}m x ${
                      selectedTrailerNoLongerAvailable.length
                    }m`,
                    vehicleType: t(`VEHICLE-NAME-${selectedVehicle?.code}`),
                  })}
                </span>
              </label>
            )}
          </div>
          <div className="ts-border-dfds-divider-dark ts-border-t">
            <div className="ts-p-dfds-s ts-flex ts-flex-row ts-items-center ts-gap-dfds-s">
              <div className="ts-flex ts-flex-col">
                <label className="ts-font-medium ts-text-cvi-brand-brand900">{t('MULTIPLE-VEHICLES-TITLE')}</label>
                {vehicleTypes?.length === 0 || multipleVehiclesSupported ? (
                  <div
                    data-e2e="multiple-vehicles-description"
                    className="ts-font-normal ts-text-sm ts-mt-dfds-xs ts-text-cvi-grey-grey800"
                  >
                    {t('MULTIPLE-VEHICLES-DESCRIPTION')}
                  </div>
                ) : (
                  <div
                    data-e2e="multiple-vehicles-error-description"
                    className="ts-font-normal ts-text-sm ts-mt-dfds-xs ts-text-cvi-grey-grey800"
                  >
                    {selectedVehicle?.code === 'NCAR'
                      ? t('MULTIPLE-VEHICLES-SELECT-VEHICLE-DESCRIPTION')
                      : t('MULTIPLE-VEHICLES-NOT-SUPPORTED-DESCRIPTION')}
                  </div>
                )}
              </div>
              <div className="ts-ml-auto">
                <Toggle
                  name="multiple-vehicles"
                  disabled={!multipleVehiclesSupported || vehicleTypes?.length === 0}
                  defaultChecked={multipleVehiclesChecked}
                  onCheckedChange={onCheckMultipleVehicle}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Selector

export type VehicleNumber = {
  value: number
}
