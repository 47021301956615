import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

declare global {
  interface Document {
    travelSearchCreate?: () => void
  }
}

if (!document.travelSearchCreate) {
  document.travelSearchCreate = function (hasOffer?: boolean) {
    ReactDOM.createRoot(document.getElementById('travel-search-root') as HTMLElement).render(
      <React.StrictMode>
        <App hasOffer={hasOffer} />
      </React.StrictMode>
    )
  }
}

if (window.location.hostname === 'localhost' || window.location.hostname.includes('travel-search')) {
  ReactDOM.createRoot(document.getElementById('travel-search-root') as HTMLElement).render(
    <React.StrictMode>
      <App hasOffer={false} />
    </React.StrictMode>
  )
}
