import { Button } from '@dfds-frontend/compass-ui'
import { useTranslation } from 'react-i18next'

interface Props {
  onClick: () => void
}

const OverlayFooter: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <div
      id="footer-content"
      className="ts-fixed ts-bottom-0 ts-left-0 ts-right-0 ts-mt-auto ts-flex ts-flex-col ts-justify-center ts-bg-white lg:ts-hidden"
    >
      <div className="ts-border-b ts-border-solid ts-border-gray-300"></div>
      <div className="ts-flex ts-w-full ts-flex-col ts-p-dfds-s">
        <Button data-e2e="overlay-apply" size="lg" stretch variant="primary" onClick={() => onClick()}>
          <div className="ts-font-medium">{t('APPLY')}</div>
        </Button>
      </div>
    </div>
  )
}

export default OverlayFooter
